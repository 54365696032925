import {css, event, grid} from '../constants';
import {Offcanvas} from 'bootstrap';

/** Class representing the webpage. */
export class Page {
  /**
   * @constructor
   * @param {boolean} debug
   */
  constructor(debug = false) {
    this.debug = debug;

    // Page constants
    this.pageId = null;
    this.header = null;
    this.topbar = null;
    this.mainbody = null;
    this.sections = null;
    this.banner = null;
    this.toTop = null;
    this.pageOverlay = null;

    // Lifecycle hooks
    this.ready = [];
    this.loaded = [];
    this.beforeUnload = () => {/* return false; */};
    this.unload = () => {/* navigator.sendBeacon("/analytics", JSON.stringify(analyticsData)); */};
    this.onScroll = [];
    this.onResize = [];
    this.onClick = [];

    this.onHeaderStickyStart = [];
    this.onHeaderStickyEnd = [];

    // Page state
    this.lastScrollPosition = 0;

    this.touchstartX = 0;
    this.touchendX = 0;
    this.touchstartY = 0;
    this.touchendY = 0;

    // Method calls
    this.initLifecycle();
  }

  /**
   * Prints out stuff conditionally.
   * @param {Data[]|Data|string} message
   * @param {boolean} predicate
   */
  log(message, predicate = false) {
    if (this.debug || predicate) {
      console.log(message);
    }
  }

  /**
   * Handles swipe events
   * @param {number} threshold
   */
  handleGesture(threshold = 150) {
    const distanceX = this.touchendX - this.touchstartX;
    const distanceY = this.touchendY - this.touchstartY;
    if (Math.abs(distanceX) >= threshold) {
      if (distanceX < 0) document.dispatchEvent(new Event(event.swipeLeft));
      if (distanceX > 0) document.dispatchEvent(new Event(event.swipeRight));
    }
    if (Math.abs(distanceY) >= threshold) {
      if (distanceY < 0) document.dispatchEvent(new Event(event.swipeUp));
      if (distanceY > 0) document.dispatchEvent(new Event(event.swipeDown));
    }
  }

  /**
   * Init the page lifecycle.
   */
  initLifecycle() {
    document.addEventListener(event.readyStateChange, () => {
      switch (document.readyState) {
        case 'loading':
          this.log('Page Loading');
          break;
        case 'interactive':
          this.log('Page Loaded');
          this.onPageReady();
          this.ready.forEach((fn) => fn());
          break;
        case 'complete':
          this.log('Page Load Complete');
          this.onPageLoaded();
          this.loaded.forEach((fn) => fn());
          break;
      }
    });
    window.addEventListener(event.beforeUnload, this.beforeUnload, {capture: true});
    window.addEventListener(event.unload, this.unload, {capture: true});
    window.addEventListener(event.scroll, (event) => {
      this.onPageScroll(event);
      this.onScroll.forEach((fn) => fn());
    });
    window.addEventListener(event.resize, () => {
      this.onPageResize();
      this.onResize.forEach((fn) => fn());
    });
    document.addEventListener(event.click, (event) => {
      this.onPageClick(event);
      this.onClick.forEach((fn) => fn(event));
    });
    document.addEventListener(event.touchstart, (event) => {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    });
    document.addEventListener(event.touchend, (event) => {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      this.handleGesture();
    });
  }

  /**
   * Actions on page ready
   * e.g. DOM initialization
   */
  onPageReady() {
    this.pageId = document.documentElement.className;
    this.header = document.body.querySelector(':scope > header');
    this.topbar = document.getElementById('main-topbar');
    this.mainnav = document.getElementById('main-nav');
    this.mainnavOffcanvas = document.getElementById('main-nav-offcanvas');
    this.mainbody = document.getElementById('main-body');

    const sections = this.mainbody.querySelectorAll('section');
    this.sections = (sections.length > 0) ? sections : null;

    const banner = document.querySelectorAll('#banner, #page-banner');
    this.banner = (banner.length > 0) ? banner[0] : null;
    this.toTop = document.querySelector('.' + css.backToTop);
    this.pageOverlay = document.getElementById('overlay');
  }

  /**
   * Actions after page load
   */
  onPageLoaded() {
    if (this.sections) {
      this.checkViewport(this.sections);
      this.onScroll.push(() => this.checkViewport(this.sections));
    }

    if (this.header && this.header.classList.contains(css.stickyHeader)) {
      this.checkStickyHeader(this.header);
      this.onScroll.push(() => this.checkStickyHeader(this.header));

      document.addEventListener(event.headerStickyStart, () => {
        this.onHeaderStickyStart.forEach((fn) => fn());
      });
      document.addEventListener(event.headerStickyEnd, () => {
        this.onHeaderStickyEnd.forEach((fn) => fn());
      });
    }

    if (this.toTop && this.toTop.dataset.mode === 'animated') {
      this.onScroll.push(() => this.checkToTopBtn());
    }

    document.addEventListener(event.scrollDown, () => {
      const hideableTopbar = this.mediaViewportUp(grid.lg) ? this.topbar : this.header;
      if (hideableTopbar) {
        hideableTopbar.classList.add(css.hidden);
      }
    });
    document.addEventListener(event.scrollUp, () => {
      const hideableTopbar = this.mediaViewportUp(grid.lg) ? this.topbar : this.header;
      if (hideableTopbar) {
        hideableTopbar.classList.remove(css.hidden);
      }
    });

    document.addEventListener(event.swipeLeft, (event) => {
      if (this.mainnavOffcanvas && this.mediaViewportDown(grid.lg)) {
        Offcanvas.getOrCreateInstance(this.mainnavOffcanvas).show();
      }
    });
    document.addEventListener(event.swipeRight, (event) => {
      if (this.mainnavOffcanvas && this.mediaViewportDown(grid.lg)) {
        Offcanvas.getOrCreateInstance(this.mainnavOffcanvas).hide();
      }
    });

    document.body.classList.add(css.loaded);
    this.removeLoadingScreen();
  }

  /**
   * Actions on page scroll
   *
   * @param {Event} e
   */
  onPageScroll(e) {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPosition !== this.lastScrollPosition) {
      const scrollEvent = (scrollPosition > this.lastScrollPosition) ? event.scrollDown : event.scrollUp;
      document.dispatchEvent(new Event(scrollEvent));
      this.lastScrollPosition = scrollPosition;
    }
  }

  /**
   * Actions on page resize
   */
  onPageResize() {}

  /**
   * Actions on page click
   * @param {Event} event
   */
  onPageClick(event) {
    const target = event.target;
    const link = target.closest('a');
    if (link && link.href) {
      const url = link.getAttribute('href');
      if (url.indexOf('//') !== -1 || url.indexOf('.pdf') !== -1) {
        event.preventDefault();
        window.open(url);
      }
    }
  }

  /**
   * Removes the pages loading screen
   */
  removeLoadingScreen() {
    const loadingScreen = document.querySelector('#loading-screen');
    if (loadingScreen) {
      loadingScreen.addEventListener(event.transitionEnd, () => loadingScreen.remove());
      loadingScreen.style.opacity = 0;
    }
  }

  /**
   * Change sticky state of header
   * @param {Object} header
   */
  checkStickyHeader(header) {
    const scrollTop = window.scrollY || window.pageYOffset;
    if (scrollTop > header.offsetHeight) {
      if (!header.classList.contains(css.sticky)) {
        header.classList.add(css.sticky);
        document.dispatchEvent(new Event(event.headerStickyStart));
      }
    } else {
      if (header.classList.contains(css.sticky)) {
        header.classList.remove(css.sticky);
        document.dispatchEvent(new Event(event.headerStickyEnd));
      }
    }
  }

  /**
   * Toggles the visibility of the toTop button
   */
  checkToTopBtn() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    const classList = this.toTop.classList;
    if (scrollPosition > 0.8 * window.outerHeight) {
      classList.remove(css.hidden);
    }

    if (scrollPosition > window.outerHeight) {
      classList.add(css.show);
    } else if (classList.contains(css.show)) {
      classList.remove(css.show);
      this.toTop.addEventListener(event.transitionEnd, () => this.toTop.classList.add(css.hidden), {once: true});
    }
  }

  /**
   * Shows page overlay
   *
   * @param {number} zIndex
   */
  showPageOverlay(zIndex = 0) {
    if (this.pageOverlay) {
      this.pageOverlay.classList.add(css.show);
      if (zIndex > 0) this.pageOverlay.style.zIndex = zIndex;
      document.body.classList.add(css.scrollLock);
    }
  }

  /**
   * Hides page overlay
   *
   * @param {boolean} hide
   */
  hidePageOverlay(hide = true) {
    if (this.pageOverlay && hide) {
      this.pageOverlay.classList.remove(css.show);
      this.pageOverlay.removeAttribute('style');
      document.body.classList.remove(css.scrollLock);
    }
  }

  /**
   * Check if element is in viewport
   *
   * @param {Element} element
   * @return {boolean}
   */
  isElementInViewport(element) {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = element.getBoundingClientRect().top + scroll;
    const viewThreshold = 0.2;

    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    };

    const bounds = {
      top: boundsTop + viewThreshold * element.clientHeight,
      bottom: boundsTop + (1 - viewThreshold) * element.clientHeight,
    };

    // console.log(element.id, viewport, bounds);

    return (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top);
  }

  /**
   * Mark visible sections
   *
   * @param {Element[]} elements
   */
  checkViewport(elements) {
    elements.forEach((element) => {
      const isVisibleClass = 'on';

      if (this.isElementInViewport(element)) {
        if (!element.classList.contains(isVisibleClass)) {
          element.classList.add(isVisibleClass);
          element.dispatchEvent(new Event(event.gotVisible));
          this.log(element.id + ' - gotVisible');
        }
      } else {
        if (element.classList.contains(isVisibleClass)) {
          element.classList.remove(isVisibleClass);
          element.dispatchEvent(new Event(event.gotInvisible));
          this.log(element.id + ' - gotInvisible');
        }
      }
    });
  }

  /**
   * Returns true if page viewport is larger or equal than the given width
   * @param {number} width
   * @return {boolean}
   */
  mediaViewportUp(width) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return (vw >= width);
  }

  /**
   * Returns true if page viewport is smaller than the given width
   * @param {number} width
   * @return {boolean}
   */
  mediaViewportDown(width) {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return (vw < width);
  }
}
