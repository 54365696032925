import {BaseComponent} from './_base';
import {css} from '../constants';
import Swiper from 'swiper/bundle';
import {SwiperParams} from './partials/swiper-params';

/** Swiper component class */
export class SwiperComponent extends BaseComponent {
  /**
   * @constructor
   * @param {boolean} debug
   */
  constructor(debug = false) {
    super(debug);
  }

  // eslint-disable-next-line require-jsdoc
  get swipers() {
    return this.swiperElemnts;
  };

  /**
   * Component initialization
   */
  initComponent() {
    this.log('Swiper init');
    this.swiperElemnts = {};
    this.breakpoints = [0, 576, 768, 992, 1200, 1400];

    const swiperElements = document.querySelectorAll('.' + css.swiper.container);
    swiperElements.forEach((swiper) => {
      const cardsPerRow = parseInt(swiper.dataset.cardsperrow) || 12 / parseInt(swiper.dataset.gridsize) || 4;

      const swiperParams = new SwiperParams({
        slidesPerView: 1,
        spaceBetween: 8,
        grabCursor: true,
        watchOverflow: true,
        speed: 1000 / cardsPerRow,
      });

      if (cardsPerRow === 1) {
        swiperParams.set('spaceBetween', 0);
      }

      if (swiper.dataset.wrap) {
        swiperParams.set('loop', true);
      } else {
        swiperParams.set('resistance', true);
        swiperParams.set('resistanceRatio', 0.75);
      }

      if (swiper.dataset.navigationStyle === 'vertical') {
        swiperParams.set('direction', 'vertical');
      }

      // Set Swiper navigation
      //
      swiperParams.set('navigation', this.navigation(swiper));
      swiperParams.set('pagination', this.pagination(swiper));
      swiperParams.set('scrollbar', this.scrollbar(swiper));

      if (swiper.dataset.ride) {
        swiperParams.enableAutoPlay({delay: 6000});
      }

      // Set Swiper effect
      //
      if (swiper.dataset.transition) {
        swiperParams.setEffect(swiper.dataset.transition);
      }

      // Set responsive behaviour
      //
      const responsive = {};
      for (const breakpoint of this.breakpoints) {
        // console.log(breakpoint);
        switch (breakpoint) {
          case 0:
            responsive[breakpoint] = {slidesPerView: 1};
            break;
          case 576:
            if (cardsPerRow > 1) responsive[breakpoint] = {slidesPerView: 1};
            break;
          case 768:
            if (cardsPerRow > 2) responsive[breakpoint] = {slidesPerView: 2};
            break;
          case 992:
            if (cardsPerRow > 2) responsive[breakpoint] = {slidesPerView: 3};
            break;
          case 1200:
            if (cardsPerRow > 3) responsive[breakpoint] = {slidesPerView: cardsPerRow};
            break;
          case 1600:
            if (cardsPerRow > 3) responsive[breakpoint] = {slidesPerView: cardsPerRow};
            break;
        }
      }
      swiperParams.set('breakpoints', responsive);

      // Init swiper
      //
      this.swiperElemnts[swiper.id] = new Swiper('#' + swiper.id, swiperParams.params);

      // console.log(swiper.id, swiperParams.get());
    });
  }

  /**
   * Set swiper navigation
   * @param {Object} swiper
   * @return {Object|boolean}
   */
  navigation(swiper) {
    let navigation = false;
    if (swiper.dataset.navigation) {
      navigation = {
        nextEl: '.' + css.swiper.buttonNext,
        prevEl: '.' + css.swiper.buttonPrev,
      };
    }
    return navigation;
  }

  /**
   * Set swiper pagination
   * @param {Object} swiper
   * @return {Object|boolean}
   */
  pagination(swiper) {
    const paginationPresets = [
      'bullets',
      'dynamicBullets',
      'fractions',
      'progressbar',
    ];
    const paginationType = swiper.dataset.pagination;

    let pagination = false;
    if (paginationType) {
      let type = paginationType;
      let dynamic = false;
      if (!paginationPresets.includes(paginationType)) {
        type = 'custom';
      } else if (paginationType === 'dynamicBullets') {
        type = 'bullets';
        dynamic = true;
      }

      pagination = {
        el: '.' + css.swiper.pagination,
        type: type,
        clickable: true,
        dynamicBullets: dynamic,
      };
    }
    return pagination;
  }

  // eslint-disable-next-line require-jsdoc
  renderCustomPagination(swiper, current, total) {
    const type = swiper.dataset.pagination;
    switch (type) {
      case 'square':
        return current + ' of ' + total;
    }
  }

  /**
   * Set swiper scrollbar
   * @param {Object} swiper
   * @return {Object|boolean}
   */
  scrollbar(swiper) {
    let scrollbar = false;
    if (swiper.dataset.scrollbar) {
      scrollbar = {
        el: '.' + css.swiper.scrollbar,
        draggable: true,
      };
    }
    return scrollbar;
  }
}
