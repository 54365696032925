/*
 * Application constants
 */

// CSS Classes
export const css = {
  accordionWrapper: 'accordion-wrapper',
  animated: 'animated',
  animating: 'animating',
  backToTop: 'back-to-top',
  btnAnimated: 'btn-animated',
  btnInner: 'btn-inner',
  card: 'card',
  dropdownMenu: 'dropdown-menu',
  dropdownToggle: 'dropdown-toggle',
  external: 'external',
  loaded: 'loaded',
  navbarToggler: 'navbar-toggler',
  mainNavCollapse: 'main-nav-collapse',
  navLink: 'nav-link',
  navOpen: 'nav-open',
  offcanvas: 'offcanvas',
  progressBar: 'progress-bar',
  scrollLock: 'scroll-lock',
  sticky: 'is-sticky',
  stickyHeader: 'header-sticky',
  opaqueHeader: 'opaque',
  waiting: 'waiting',
  visible: 'visible',
  invisible: 'invisible',
  show: 'show',
  hidden: 'hidden',
  hide: 'hide',
  swiper: {
    container: 'swiper-container',
    buttonPrev: 'swiper-button-prev',
    buttonNext: 'swiper-button-next',
    buttonDisabled: 'swiper-button-disabled',
    buttonHidden: 'swiper-button-hidden',
    buttonLock: 'swiper-button-lock',
    pagination: 'swiper-pagination',
    scrollbar: 'swiper-scrollbar',
    noSwiping: 'swiper-no-swiping',
    slideBlank: 'swiper-slide-invisible-blank',
    slide: 'swiper-slide',
    slideActive: 'swiper-slide-active',
    slideVisible: 'swiper-slide-visible',
    slideDuplicate: 'swiper-slide-duplicate',
    slideDuplicateActive: 'swiper-slide-duplicate-active',
    slideNext: 'swiper-slide-next',
    slideDuplicateNext: 'swiper-slide-duplicate-next',
    slidePrev: 'swiper-slide-prev',
    slideDuplicatePrev: 'swiper-slide-duplicate-prev',
    wrapper: 'swiper-wrapper',
  },
  listImageFeature: {
    module: 'list-image-features',
    container: 'image-feature-container',
    imageContainer: 'image-container',
    featureItem: 'feature-item',
    featureImage: 'feature-image',
  },
  floatingActionMenu: {
    container: 'floating-action-container',
    menu: 'floating-action-menu',
  },
  bottomMobileMenu: {
    menu: 'bottom-mobile-menu',
    container: 'bottom-mobile-container',
  },
};

// Events
export const event = {
  bsCollapseShow: 'show.bs.collapse',
  bsCollapseHide: 'hide.bs.collapse',
  bsDropdownHidden: 'hidden.bs.dropdown',
  bsDropdownHide: 'hide.bs.dropdown',
  bsDropdownShow: 'show.bs.dropdown',
  bsDropdownShown: 'shown.bs.dropdown',
  bsOffcanvasHidden: 'hidden.bs.offcanvas',
  bsOffcanvasHide: 'hide.bs.offcanvas',
  animated: 'animated',
  animationStart: 'animationstart',
  animationEnd: 'animationend',
  transitionStart: 'transitionstart',
  transitionEnd: 'transitionend',
  mouseEnter: 'mouseenter',
  mouseLeave: 'mouseleave',
  gotVisible: 'gotVisible',
  gotInvisible: 'gotInvisible',
  headerStickyStart: 'headerStickyStart',
  headerStickyEnd: 'headerStickyEnd',
  readyStateChange: 'readystatechange',
  beforeUnload: 'beforeunload',
  unload: 'unload',
  scroll: 'scroll',
  scrollUp: 'scrollup',
  scrollDown: 'scrolldown',
  touchstart: 'touchstart',
  touchend: 'touchend',
  swipeLeft: 'swipe-left',
  swipeRight: 'swipe-right',
  swipeUp: 'swipe-up',
  swipeDown: 'swipe-down',
  loaded: 'load',
  resize: 'resize',
  click: 'click',
  ready: 'DOMContentLoaded',

};

// Data attributes
export const data = {
  bsSlide: 'data-bs-slide',
  bsSlideTo: 'data-bs-slide-to',
  bsToggle: 'data-bs-toggle',
};

// Grid breakpoints
export const grid = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
