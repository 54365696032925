'use strict';

// import 'owl.carousel';

// -------------------------------------------\\
// Client Scripts                            \\
// ------------------------------------------\\
import './init';
// import './animated-button';
// -------------------------------------------\\
// Stylesheets                               \\
// ------------------------------------------\\
import '../scss/main.scss';
