
/** Base component class */
export class BaseComponent {
  /**
   * @constructor
   * @param {boolean} debug
   */
  constructor(debug = false) {
    this.debug = debug;
    this.initComponent();
  }

  /**
   * Prints out stuff conditionally.
   * @param {Data[]|Data|string} message
   * @param {boolean} predicate
   */
  log(message, predicate = false) {
    if (this.debug || predicate) {
      console.log(message);
    }
  }

  /**
   * Init function stub
   */
  initComponent() {}
}
