'use strict';
// import AudioPlayer from './components/audio-player';
import {Carousel, Collapse, Offcanvas} from 'bootstrap';
import {Page} from './classes/page';
import {css, data, event} from './constants';
import {Animations} from './animate';
import {SwiperComponent} from './components/swiper';

/* ---------------------------------------------------- */
/* Document variables
------------------------------------------------------ */
// let audioPlayers = [];

// const headerHeight = 52;

const page = new Page(false);

// Actions after page ready
//
page.ready.push(() => {
  // console.log('Page Ready Function');

  // Init page animations
  // eslint-disable-next-line no-unused-vars
  const animations = new Animations(page);

  // Init Swiper carousels
  // eslint-disable-next-line no-unused-vars
  const swiperComponent = new SwiperComponent(false);

  // initListFeatures();
  // animateSkillSets(page);
  // bodyNavbarState();
  smoothScrollAnchors(true);
  ajaxContactForm();

  initCustomDropdownMenu('.' + css.floatingActionMenu.menu);
  initCustomDropdownMenu('.' + css.bottomMobileMenu.menu, 1010);
});

// Actions after page load
//
page.loaded.push(() => {
  // console.log('Page Loaded Function');
});

// Actions before page unload
//
page.beforeUnload = (event) => {
  event.preventDefault();
  return event.returnValue = 'Are you sure you want to exit?';
};

// Actions on page scroll
//
// page.onScroll.push(() => {});

// Click actions
//
page.onClick.push((e) => {
  const target = e.target;
  // console.log(target);

  // Hide mobile navbar when clicking outside or on mobile link
  const navbarToggler = target.closest('.' + css.navbarToggler);
  if (!navbarToggler) {
    const visibleNav = document.querySelector('.navbar-collapse.show');
    if (visibleNav) {
      Collapse.getInstance(visibleNav).hide();
    }
  }

  if (target.classList.contains(css.navLink)) {
    const offcanvas = target.closest('.' + css.offcanvas);
    if (offcanvas) {
      const isDropdownToggle = target.classList.contains(css.dropdownToggle);
      if (!isDropdownToggle) {
        const hash = target.getAttribute('href');
        if (hash) {
          offcanvas.addEventListener(event.bsOffcanvasHidden, () => {
            const scrollTarget = document.querySelector(hash);
            if (scrollTarget) scrollTarget.scrollIntoView({behavior: 'smooth'});
          }, {once: true});
        }
        Offcanvas.getInstance(offcanvas).hide();
      }
    }
  }

  // Gallery modal carousel behaviour
  if (target.dataset.bsToggle === 'modal' && target.dataset.bsSlideTo) {
    const parentId = target.dataset.bsTarget.split('-')[0].replace('#', '');
    // const modal = document.querySelector(target.dataset.target);
    const carousel = document.getElementById('carousel-' + parentId);
    const bsCarousel = Carousel.getInstance(carousel) || new Carousel(carousel);
    if (bsCarousel) {
      bsCarousel.to(target.dataset.bsSlideTo);
    }
  }

  // Accordion behaviour
  if (target.classList.contains('.' + css.accordionWrapper)) {
    const input = target.nodeName === 'INPUT' ? target : null;

    if (input) {
      const accordion = target.closest('.' + css.accordionWrapper);
      const parent = input.parentNode;
      const label = parent.querySelector(`[for="${input.id}"]`);

      if (input.classList.contains('open')) {
        input.checked = !input.checked;
        Array.from(accordion.querySelectorAll('.open')).forEach(function(el) {
          el.classList.remove('open');
        });
      } else {
        input.classList.add('open');
        if (document.body.contains(label)) {
          const article = label.nextElementSibling;
          article.addEventListener('transitionend', () => {
            label.scrollIntoView({behavior: 'smooth'});
          }, {once: true});
        }
      }
    }
  }

  // Dropdown toggle clicks
  /* if (target.classList.contains(css.dropdownToggle)) {} */
});

function initCustomDropdownMenu(selector, overlayLayer = 0) {
  const menus = document.querySelectorAll(selector);
  if (menus.length) {
    menus.forEach((menu) => {
      const buttons = menu.querySelectorAll('.' + css.dropdownToggle);

      if (buttons.length) {
        buttons.forEach((button) => {
          button.addEventListener(event.bsDropdownShow, () => page.showPageOverlay(overlayLayer));
          button.addEventListener(event.bsDropdownHidden, () => {
            const activated = [...buttons].filter((button, index) => button.classList.contains(css.show));
            const hide = (activated.length === 0);
            page.hidePageOverlay(hide);
          });
        });
      }
    });
  }
}

function bodyNavbarState() {
  // Toggle navbar open state on body
  const mainNavCollapse = document.getElementById('main-nav-collapse');
  if (mainNavCollapse) {
    mainNavCollapse.addEventListener(event.bsCollapseShow, () => {
      // console.log(event.bsCollapseShow);
      document.body.classList.toggle(css.navOpen);
    });
    mainNavCollapse.addEventListener(event.bsCollapseHide, () => {
      // console.log(event.bsCollapseHide);
      document.body.classList.toggle(css.navOpen);
    });
  }
}

function animateSkillSets(page) {
  const skillsets = document.querySelectorAll('.skill-sets .skill-set');

  if (skillsets && skillsets.length) {
    // Handle skill progress
    skillsets.forEach((set) => {
      set.addEventListener(event.gotVisible, (event) => {
        const progressBars = set.querySelectorAll('.' + css.progressBar);
        progressBars.forEach((bar) => {
          const progress = bar.dataset.width + '%';
          bar.style.width = progress;
          bar.textContent = progress;
        });
        // console.log('gotVisible: ', event.target);
      });
    });

    page.loaded.push(() => {
      page.checkViewport(skillsets);
    });

    page.onScroll.push(() => {
      page.checkViewport(skillsets);
    });
  }
}

function initListFeatures() {
  console.log('Init list features');
  const listFeatures = document.querySelectorAll('.' + css.listImageFeature.module);
  for (const feature of listFeatures) {
    new ListImageFeaturesComponent(feature);
  }
}

function smoothScrollAnchors(offset = false) {
  // Smooth scroll anchors
  const selector = `a[href^="#"]:not([${data.bsSlide}]):not([${data.bsToggle}])`;
  document.querySelectorAll(selector).forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      e.preventDefault();
      const hash = anchor.getAttribute('href');
      if (hash.length > 1) {
        const scrollTarget = document.querySelector(hash);
        if (offset) {
          let headerHeight = page.header.offsetHeight;
          let scrollTop = scrollTarget.getBoundingClientRect().top - headerHeight;
          const correctionOffset = (scrollTop > 0) ? 80 : 0;
          if (scrollTop > correctionOffset || scrollTop < -1 * correctionOffset) {
            window.addEventListener(event.transitionEnd, () => {
              headerHeight = page.header.offsetHeight;
              scrollTop = scrollTarget.getBoundingClientRect().top - headerHeight + correctionOffset;
              window.scrollBy({top: scrollTop, behavior: 'smooth'});
            }, {once: true});

            window.scrollBy({top: scrollTop, behavior: 'smooth'});
          }
        } else {
          scrollTarget.scrollIntoView({behavior: 'smooth'});
        }
      }
    });
  });
}

// eslint-disable-next-line no-unused-vars
function fadeNavigation() {
  const h = page.header.offsetHeight;
  const scrollTop = window.scrollY || window.pageYOffset;
  const vw = screen.width;
  const vh = screen.height;

  // console.log(scrollTop, h, vw, vh);

  setClass(page.header, 'opaque', (scrollTop >= vh * 0.8));

  if (page.mainnav && vw > 768) {
    const hideCondition = (scrollTop > h * 0.2) && (scrollTop < vh * 0.8);
    setClass(page.mainnav, 'invisible', hideCondition);
  }
}

function setClass(element, classes, condition = true) {
  if (condition) {
    if (!element.classList.contains(classes)) {
      element.classList.add(classes);
    }
  } else {
    if (element.classList.contains(classes)) {
      element.classList.remove(classes);
    }
  }
}

function ajaxContactForm(id = 'contact-form') {
  const form = document.getElementById(id);
  if (form) {
    form.addEventListener('submit', function(event) {
      event.preventDefault();

      const result = document.querySelector('#form-message');
      const action = form.getAttribute('action');
      const method = form.getAttribute('method');

      fetch(action, {method: method, body: new FormData(form)}).then(function(response) {
        return (response.ok) ? response.text() : response.json();
      }).then(function(output) {
        if (result) {
          result.innerHTML = output;
        }
      }).catch(function(error) {
        if (result) {
          result.innerHTML = 'Error: ' + error;
        }
        throw new Error(error);
      });
    });
  }
}
