import {css} from '../../constants';

/** Abstraction layer for Swiper parameters */
export class SwiperParams {
  /**
   * Construct Swiper parameters
   * @param {Object} config
   */
  constructor(config = {}) {
    this.defaults = {
      init: true,
      direction: 'horizontal',
      touchEventsTarget: 'container',
      initialSlide: 0,
      speed: 300,
      cssMode: false,
      updateOnWindowResize: true,
      resizeObserver: false,
      nested: false,
      enabled: true,
      width: null,
      height: null,
      preventInteractionOnTransition: false,
      userAgent: null,
      url: null,
      edgeSwipeDetection: false,
      edgeSwipeThreshold: 20,
      freeMode: false,
      freeModeMomentum: true,
      freeModeMomentumRatio: 1,
      freeModeMomentumBounce: true,
      freeModeMomentumBounceRatio: 1,
      freeModeMomentumVelocityRatio: 1,
      freeModeSticky: false,
      freeModeMinimumVelocity: 0.02,
      autoHeight: false,
      setWrapperSize: false,
      virtualTranslate: false,
      effect: 'slide',
      breakpointsBase: 'window',
      spaceBetween: 0,
      slidesPerView: 1,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'column',
      slidesPerGroup: 1,
      slidesPerGroupSkip: 0,
      centeredSlides: false,
      centeredSlidesBounds: false,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      normalizeSlideIndex: true,
      centerInsufficientSlides: false,
      watchOverflow: false,
      roundLengths: false,
      touchRatio: 1,
      touchAngle: 45,
      simulateTouch: true,
      shortSwipes: true,
      longSwipes: true,
      longSwipesRatio: 0.5,
      longSwipesMs: 300,
      followFinger: true,
      allowTouchMove: true,
      threshold: 0,
      touchMoveStopPropagation: false,
      touchStartPreventDefault: true,
      touchStartForcePreventDefault: false,
      touchReleaseOnEdges: false,
      uniqueNavElements: true,
      resistance: false,
      resistanceRatio: 0.85,
      watchSlidesProgress: false,
      watchSlidesVisibility: false,
      grabCursor: true,
      preventClicks: true,
      preventClicksPropagation: true,
      slideToClickedSlide: false,
      preloadImages: true,
      updateOnImagesReady: true,
      loop: false,
      loopAdditionalSlides: 0,
      loopedSlides: null,
      loopFillGroupWithBlank: false,
      loopPreventsSlide: true,
      allowSlidePrev: true,
      allowSlideNext: true,
      swipeHandler: null,
      noSwiping: true,
      noSwipingClass: css.swiper.noSwiping,
      noSwipingSelector: null,
      passiveListeners: true,
      containerModifierClass: css.swiper.container + '-',
      slideClass: css.swiper.slide,
      slideBlankClass: css.swiper.slideBlank,
      slideActiveClass: css.swiper.slideActive,
      slideDuplicateActiveClass: css.swiper.slideDuplicateActive,
      slideVisibleClass: css.swiper.slideVisible,
      slideDuplicateClass: css.swiper.slideDuplicate,
      slideNextClass: css.swiper.slideNext,
      slideDuplicateNextClass: css.swiper.slideDuplicateNext,
      slidePrevClass: css.swiper.slidePrev,
      slideDuplicatePrevClass: css.swiper.slideDuplicatePrev,
      wrapperClass: css.swiper.wrapper,
      runCallbacksOnInit: true,
      _emitClasses: false,
      observer: false,
      observeParents: false,
      observeSlideChildren: false,
      virtual: {
        enabled: false,
        slides: [],
        cache: true,
        renderSlide: null,
        renderExternal: null,
        renderExternalUpdate: true,
        addSlidesBefore: 0,
        addSlidesAfter: 0,
      },
      keyboard: {
        enabled: false,
        onlyInViewport: true,
        pageUpDown: true,
      },
      mousewheel: {
        enabled: false,
        releaseOnEdges: false,
        invert: false,
        forceToAxis: false,
        sensitivity: 1,
        eventsTarget: 'container',
        thresholdDelta: null,
        thresholdTime: null,
      },
      navigation: {
        nextEl: css.swiper.buttonNext,
        prevEl: css.swiper.buttonPrev,
        hideOnClick: false,
        disabledClass: css.swiper.buttonDisabled,
        hiddenClass: css.swiper.buttonHidden,
        lockClass: css.swiper.buttonLock,
      },
      pagination: false,
      scrollbar: false,
      parallax: {
        enabled: false,
      },
      controller: {
        inverse: false,
        by: 'slide',
      },
      hashNavigation: {
        enabled: false,
        replaceState: false,
        watchState: false,
      },
      autoplay: {
        enabled: false,
        delay: 3000,
        waitForTransition: true,
        disableOnInteraction: true,
        stopOnLastSlide: false,
        reverseDirection: false,
        pauseOnMouseEnter: false,
      },
      fadeEffect: {
        crossFade: false,
      }
    };
    this.effects = {
      fade: {
        crossFade: true,
      },
      cube: {
        slideShadows: false,
        shadow: true,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      coverflow: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        scale: 1,
        modifier: 1,
        slideShadows: true,
      }
    };
    this.params = this.applyDefaults(config, this.defaults);
  }

  /**
   * Set a specific Swiper parameter
   * @param {string} name
   * @param {Object|string|number} value
   */
  set(name, value) {
    this.params[name] = value;
  }

  /**
   *  Returns a specific Swiper parameter if a parameter name is given else return the whole parameter set.
   *  Returns 'null' if there is no requested parameter.
   * @param {string|null} name
   * @return {null|Object|string|number}
   */
  get(name = null) {
    if (!name) {
      return this.params;
    }
    if (this.params.hasOwnProperty(name)) {
      return this.params[name];
    }
    return null;
  }

  /**
   * Sets a slideshow effect
   * @param {string} name
   * @param {Object} config
   */
  setEffect(name, config = {}) {
    if (this.effects.hasOwnProperty(name)) {
      this.set('effect', name);
      const effectConfig = this.applyDefaults(config, this.effects[name]);
      console.log(effectConfig);
      if (effectConfig.length) {
        this.set(name + 'Effect', effectConfig);
      }
    }
  }

  /**
   * Enables and configures autoplay
   * @param {Object} config
   */
  enableAutoPlay(config = {}) {
    const autoplayConfig = this.params['autoplay'];
    autoplayConfig['enabled'] = true;
    for (const [key, setting] of Object.entries(config)) {
      autoplayConfig[key] = setting;
    }
    this.params['autoplay'] = autoplayConfig;
  }

  /**
   * Applies default values to configuration
   * @param {Object} config
   * @param {Object} defaults
   * @return {Object}
   */
  applyDefaults(config, defaults) {
    const resultConfig = config;
    for (const [key, setting] of Object.entries(defaults)) {
      if (!config.hasOwnProperty(key)) {
        resultConfig[key] = setting;
      }
    }
    return resultConfig;
  }
}
