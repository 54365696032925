'use strict';
import {css, event} from './constants';

// eslint-disable-next-line valid-jsdoc
/**
 * Class for handling page animations
 * @typedef {{name: string, duration: string|undefined, delay: string|undefined}} Animation
 */
export class Animations {
  /**
   * @param {Page} page
   */
  constructor(page) {
    this.animateNamespace = 'animate__';
    this.animateAnimated = this.animateNamespace + css.animated;

    this.page = page;
    this.initAnimations();
  }

  /**
   * Run an animation from animate.css on the element and removed animation classes afterwards
   * @param {Object} element
   * @param {Animation} animation
   * @param {boolean} cleanUp
   * @param {string} prefix
   * @return {Promise<any>}
   */
  runAnimation(element, animation, cleanUp = false, prefix = this.animateNamespace) {
    // We create a Promise and return it
    return new Promise((resolve, reject) => {
      const animationName = prefix + animation.name;
      const animateDuration = animation.duration ? prefix + animation.duration : null;
      const animateDelay = animation.delay ? prefix + animation.delay : null;
      const classList = element.classList;

      const animateClasses = [
        this.animateAnimated, animationName,
        animateDuration ? animateDuration : '',
        animateDelay ? animateDelay : '',
      ].filter((value) => value);

      classList.add(...animateClasses);

      element.addEventListener(event.animationStart, () => {
        classList.add(css.show);
        classList.replace(css.waiting, css.animating);
      }, {once: true});

      if (cleanUp) {
        element.addEventListener(event.animationEnd, () => classList.remove(css.show), {once: true});
      }

      // When the animation ends, we clean the classes and resolve the Promise
      element.addEventListener(event.animationEnd, (event) => {
        event.stopPropagation();
        classList.remove(...animateClasses, css.animating);
        resolve('Animation ended');
      }, {once: true});
    });
  }

  /**
   * Play an delayed animation from animate.css on an array of elements
   * @param {Array} elements
   * @param {string[]} animations
   * @param {number} delay
   * @param {boolean} cleanUp
   */
  delayedAnimation(elements, animations, delay = 100, cleanUp = false) {
    if (elements.length > 0) {
      const fallbackAnimation = animations[0];
      elements.forEach((element, index) => {
        element.classList.add(css.waiting);
        const animationName = animations[index] || fallbackAnimation;
        setTimeout(() => this.runAnimation(element, {name: animationName}), (index + 1) * delay);
      });
    }
  }

  /**
   * Site animation initialization
   */
  initAnimations() {
    // Makes all non-waiting animated elements visible on animation start
    const animatedElements = document.querySelectorAll('.' + this.animateAnimated + `:not(${css.waiting})`);
    animatedElements.forEach((element) => {
      element.addEventListener(event.animationStart, () => element.classList.add(css.show), {once: true});
    });

    const sections = this.page.sections;
    if (sections) {
      sections.forEach((section) => {
        if (section.id) {
          this.sectionAnimation(section);
          this.cardsAnimation(section);

          if (section.classList.contains('content-grid')) {
            this.cellAnimations(section);
          }
        }

        // Bouncing system logos
        if (section.id === 'home-systeme') {
          section.addEventListener(event.gotVisible, (event) => {
            const elements = event.target.querySelectorAll('img');
            this.delayedAnimation(elements, ['bounceInRight']);
          }, {once: true});
        }
      });
    }

    // Home page caption animation
    if (this.page.pageId === 'home' && this.page.banner != null) {
      const activeCarouselItem = this.page.banner.querySelector('.carousel-item.active');
      if (activeCarouselItem) {
        const elements = activeCarouselItem.querySelectorAll('.caption-body > *');
        this.delayedAnimation(elements, ['slideInLeft'], 250);
      }
    }
  }

  /**
   * Handles section animations
   * @param {Object} section
   * @param {string|null} delay
   * @param {string|null} duration
   */
  sectionAnimation(section, delay = null, duration = null) {
    const animationName = section.dataset.animation;
    const container = document.querySelector(`#${section.id} > .section-container`);
    if (animationName && container) {
      const animateDuration = duration || section.dataset.animationDuration;
      const animateDelay = delay || section.dataset.animationDelay;

      section.addEventListener(event.animationEnd, () => {
        delete section.dataset.animation;
        delete section.dataset.animationDuration;
      }, {once: true});

      section.addEventListener(event.gotVisible, () => {
        this.runAnimation(container, {name: animationName, duration: animateDuration, delay: animateDelay});
      }, {once: true});
    }
  }

  /**
   * Handles card animations
   * @param {Object} section
   * @param {string|null} duration
   * @param {string|null} delay
   */
  cardsAnimation(section, duration = null, delay = null) {
    const animation = section.dataset.cardsAnimation;
    const cards = section.querySelectorAll('.card');
    if (animation && cards.length) {
      const animateClasses = [
        duration ? this.animateNamespace + duration : '',
        delay ? this.animateNamespace + delay : '',
      ].filter((it) => it);

      cards.forEach((card) => card.classList.add(...animateClasses));

      section.addEventListener(event.gotVisible, () => {
        this.delayedAnimation(cards, [animation], 50);
        delete section.dataset.cardsAnimation;
      }, {once: true});
    }
  }

  /**
   * Handles Content Grid cell animation
   * @param {Object} section
   */
  cellAnimations(section) {
    const cells = [...section.querySelectorAll('.cell')].filter((cell) => 'animation' in cell.dataset);
    cells.forEach((cell) => {
      const animation = cell.dataset.animation;
      const duration = cell.dataset.animationDuration;
      const delay = cell.dataset.animationDelay;

      if (animation) {
        cell.addEventListener(event.animationEnd, () => {
          delete cell.dataset.animation;
          delete cell.dataset.animationDuration;
        }, {once: true});

        section.addEventListener(event.gotVisible, () => {
          this.runAnimation(cell, {name: animation, duration: duration, delay: delay});
        }, {once: true});
      }
    });
  }
}
